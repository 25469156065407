<template>
    <router-link :to="rota" class="btn btn-primary text-center shadow card-botao" v-if="possuiPermissao">
        <div class="d-flex justify-content-start">
            <div class="d-flex justify-content-center align-items-center mr-3 ml-2">
                <span class="icone-botao label-orange">
                    <i :class="icone"></i>
                </span>
            </div>
            <div class="d-flex flex-column align-items-start justify-content-center">
                <span class="pr-1">{{ tituloBotao }}</span>
                <span class="label-orange fz-50">{{ subtituloBotao }}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'BotaoOpcaoHome',

    props: {
        subtituloBotao: null,
        tituloBotao: null,
        icone: null,
        rota: null,
        exibeDescricao: null
    },

    computed: {
        possuiPermissao() {
            return true;
        },

        ...mapGetters({
            isMobile: 'appData/ehWMSMobile'
        })
    }
};
</script>

<style scoped>
a.btn {
    line-height: unset !important;
}
.icone-botao {
    font-size: 2rem;
}
.card-botao {
    border-right: solid;
    border-color: #ffb200;
}

@media only screen and (max-width: 350px) {
    a {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
</style>
